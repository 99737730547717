import path from "path";

export interface GameState {
    id: string,
    players: { [id:string]: Player },
    teams: { [id:string]: Team },
    active_team: string,
    phase: string,
    word: string,
}

export interface Team {
    id: string;
    name: string;
    score: number;
    players: string[];
}

export interface Player {
    id: string;
    name: string;
    team: string | undefined;
    word: string | undefined;
    hide_word: boolean;
}

class Api
{
    constructor( url: string )
    {
        this.backend = url;
    }


    backend : string;

    async register( room: string, name: string ) : Promise<Player>
    {
        const response = await fetch( this._url( `/api/rooms/${room}/players` ), {

            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
            })
        } );

        const payload = await response.json();
        return payload;
    }

    async joinTeam( room: string, player: string, team: string ) : Promise<Team>
    {
        const response = await fetch( this._url( `/api/rooms/${room}/players/${player}/team` ), {

            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(team),
        } );

        const payload = await response.json();
        return payload;
    }

    async setHint( room: string, player: string, hint: string ) : Promise<Player>
    {
        const response = await fetch( this._url( `/api/rooms/${room}/players/${player}/hint` ), {

            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(hint),
        } );

        const payload = await response.json();
        return payload;
    }

    async clearHint( room: string, player: string ) : Promise<Player>
    {
        const response = await fetch( this._url( `/api/rooms/${room}/players/${player}/hint` ), {

            method: "DELETE",
        } );

        const payload = await response.json();
        return payload;
    }

    async setHidden( room: string, player: string, hidden: boolean ) : Promise<Player>
    {
        const response = await fetch( this._url( `/api/rooms/${room}/players/${player}/hidden` ), {

            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(hidden),
        } );

        const payload = await response.json();
        return payload;
    }

    async kick( room: string, player: string )
    {
        const response = await fetch( this._url( `/api/rooms/${room}/players/${player}/team` ), {

            method: "DELETE",
        } );
    }

    async getTeams( room: string ) : Promise<Team[]>
    {
        const response = await fetch( this._url( `/api/rooms/${room}/teams` ), {
            method: "GET",
        } );

        const payload = await response.json();
        return payload;
    }

    async setPhase( room: string, phase: string ) 
    {
        const response = await fetch( this._url( `/api/rooms/${room}/phase` ), {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(phase),
        } );
    }

    _url( path: string )
    {
        return this.backend + path;
    }
}

const api = new Api( process.env.REACT_APP_BACKEND! );
export default api;