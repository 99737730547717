import { Form, redirect, ActionFunction } from 'react-router-dom';
import { SyntheticEvent, FormEvent } from 'react';

import api from '../api';

export default function Home() {
    return (
        <Form method="post">
            <p>
                <label>Name<input type="text" name="name" /></label>
            </p>
            <p>
                <input type="submit" name="join" value="Join" />
            </p>
        </Form>
    )

}

export const action : ActionFunction = async ({request, params }) => {
    const formData = await request.formData();
    const name = formData.get("name")!.toString()
    const player = await api.register( "pikkujoulut", name );

    sessionStorage.setItem( "player", player.id );
    return redirect( "/pikkujoulut" );
}