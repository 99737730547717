import { SyntheticEvent } from 'react';
import api, { GameState, Player } from '../api';
import PlayArea from './play-area';

interface PlayAreaProps {
    state: GameState,
    playerId: string,
}

export default function HintForm(props : PlayAreaProps) {
    const gameState = props.state;
    const player = gameState.players[props.playerId];

    const onSubmit = async ( e: SyntheticEvent<HTMLFormElement, SubmitEvent> ) => {
        e.preventDefault();

        if( player.word ) {
            api.clearHint( gameState.id, player.id );
        } else {
            const hint = ( e.currentTarget.elements.namedItem("hint") as HTMLInputElement ).value;
            api.setHint( gameState.id, player.id, hint );
        }
    }

    return <>
        <div id="hint" className={player.hide_word ? "hidden" : "visible"}>
            <div className="word-label">The word is</div>
            <div className="word">{gameState.word}</div>
            <form onSubmit={onSubmit}>
                {
                    player.word
                        ? <div className="my-hint">{player.word}</div>
                        : <input type="text" placeholder="Write hint" name="hint" value={player.word} />
                }
                <input type="submit" value={player.word ? "Edit" : "OK"}></input>
            </form>
        </div>
    </>;
}