import { useState, useEffect, SyntheticEvent } from 'react';

import api, { Team, Player, GameState } from '../api';
import JoinRoom from '../components/join-room';
import NewRoom from '../components/new-room';
import PlayArea from '../components/play-area';
import PlayerList from '../components/player-list';
import HintForm from '../components/hint-form';
import Hints from '../components/hints';

interface WSEvent {
    GameState: GameState,
}

export default function Room() {

    const [gameState, setGameState] = useState<GameState>();
    const [teamId, setTeamId] = useState<string | undefined>();
    const [playerId, setPlayerId] = useState<string | undefined>(() => sessionStorage.getItem('playerId') || undefined);
    const [error, setError] = useState<string>();

    const currentPlayer = () => playerId ? gameState?.players[playerId] : undefined
    const currentTeam = () => playerId ? gameState?.players[playerId] : undefined;

    const roomId = "pikkujoulut";

    useEffect(() => {
        const url = process.env.REACT_APP_BACKEND?.replace(/^http/, "ws")
        const socket = new WebSocket(`${url}/api/rooms/${roomId}/ws`)

        socket.addEventListener("message", (event) => {
            const data = JSON.parse(event.data) as WSEvent;
            console.log(data);
            if (data.GameState) {
                console.log( "Setting gamestate");
                setGameState(data.GameState);
            }
        })
    }, [ roomId ]);

    useEffect(() => {
        if( playerId )
            sessionStorage.setItem('playerId', playerId);
    }, [ playerId ]);

    useEffect(() => {
        if( ! gameState )
            return;
        if( ! playerId )
            return;
        
        const player = currentPlayer();
        if( ! player )
        {
            console.log( "Clearing player ID" );
            setPlayerId( undefined );
            setTeamId( undefined );
            return;
        }

        let teamId = undefined;
        for( const team of Object.values(gameState.teams))
        {
            if( team.id === player.team )
                teamId = team.id;
        }
        setTeamId( teamId );
    }, [ gameState ]);


    if( !gameState ) {
        return (
            <div>Loading...</div>
        )
    }

    const register = async ( e: SyntheticEvent<HTMLFormElement, SubmitEvent> ) => {
        e.preventDefault();
        setError( undefined );

        const name = ( e.currentTarget.elements.namedItem("name") as HTMLInputElement ).value;
        const player = await api.register( roomId, name );

        console.log( "Setting player ID: ", player.id );
        setPlayerId( player.id );
    }

    const joinTeam = async ( teamId: string ) => {
        if( playerId == undefined )
            throw new Error("Player required for team join");

        const team = await api.joinTeam( roomId, playerId, teamId );
        setTeamId( team.id );
    }

    console.log( "Player ID: ", playerId );
    if( !playerId )
    {
        return (
            <form onSubmit={register}>
                <p>
                    <label>Name<input type="text" name="name" /></label>
                </p>
                <p>
                    <input type="submit" name="join" value="Join" />
                </p>
            </form>
        )
    }

    if( gameState.phase == "WriteHints" ) {
        return <HintForm playerId={playerId} state={gameState}/>
    }

    if( gameState.phase == "Guess" ) {
        return <Hints state={gameState} />
    }

    if( gameState.phase == "Score" ) {
        return <PlayerList playerId={playerId} state={gameState} show={true} onclick={(a,b) => {}}/>
    }

    return <PlayerList playerId={playerId} state={gameState} show={false} onclick={(a,b) => {}}/>
}
