import { useState, useEffect, SyntheticEvent, ReactNode } from 'react';

import api, { Team, Player, GameState } from '../api';
import JoinRoom from '../components/join-room';
import NewRoom from '../components/new-room';
import PlayArea from '../components/play-area';
import PlayerList from '../components/player-list';

interface WSEvent {
    GameState: GameState,
}

export default function Room() {

    const admin = true;

    const [gameState, setGameState] = useState<GameState>();
    const [error, setError] = useState<string>();

    const roomId = "pikkujoulut";

    useEffect(() => {
        const url = process.env.REACT_APP_BACKEND?.replace(/^http/, "ws")
        const socket = new WebSocket(`${url}/api/rooms/${roomId}/ws`)

        socket.addEventListener("message", (event) => {
            const data = JSON.parse(event.data) as WSEvent;
            console.log(data);
            if (data.GameState) {
                setGameState(data.GameState);
            }
        })
    }, [ roomId ]);

    if( !gameState ) {
        return (
            <div>Loading...</div>
        )
    }
    
    const toggleWord = async (roomId: string, player: Player) => {
        await api.setHidden(roomId, player.id, !player.hide_word);
    };

    const phases = [
        "Setup",
        "Prepare",
        "WriteHints",
        "RemoveHints",
        "Guess",
        "Score",
    ];
    const phaseItems = phases.map(p => <li onClick={() => api.setPhase(roomId, p)} className={gameState.phase == p ? "active" : undefined}>{p}</li>);

    const currentIndex = () => {
        for( var i = 0; i < phases.length; i++ ) {
            if( phases[i] === gameState.phase )
                return i;
        }
        return 0;
    }

    const setPhase = (offset: number) => {
        const current = currentIndex();
        let next = (current + offset) % phases.length;
        if( next === 0 )
            next = (next + offset) % phases.length;
        api.setPhase(roomId, phases[next]);
    };

    return (
        <>
        <div id="admin-area">

            <div id="word">
                <h3>Word: {gameState.word}</h3>
            </div>

            <div id="phases">
                <h3>Phase:</h3>
                <div className="flex">
                    <button className="previous-phase" onClick={() => setPhase(-1)}>Previous</button>
                    <button className="next-phase" onClick={() => setPhase(1)}>Next</button>
                </div>
                <ul>
                    {phaseItems}
                </ul>
            </div>

            <div id="admin-players">
                <PlayerList state={gameState} show={true} onclick={toggleWord} />
            </div>
        </div>
        </>
    )
}

/*
function Setup(options: {
    state: GameState
}) {
    return <>
        <PhaseView state={options.state} phase="Setup">
        </PhaseView>
    </>;
}

function Prepare(options: {
    state: GameState
}) {
    return <>
        <PhaseView state={options.state} phase="Prepare">
        </PhaseView>
    </>;
}

function PhaseView(options: {
    state: GameState,
    phase: string,
    children: ReactNode,
})
{
    const isActive = options.state.phase == options.phase;
    return <div className={isActive ? "phase active" : "phase"}>
        <h2>{options.phase}</h2>
        {options.children}
    </div>
}
*/
