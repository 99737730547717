import api, { GameState, Player } from '../api';

interface PlayAreaProps {
    state: GameState,
    show: boolean,
    playerId?: string,
    onclick: (roomId: string, player: Player) => void,
}

export default function PlayArea(props : PlayAreaProps) {
    const gameState = props.state;

    const teamElements = Object.values(gameState.teams).map(t => {
        const players = t.players.map(p => {
            const player = gameState.players[p];
            return <li key={p} className={player.hide_word ? "player hidden" : "player visible"} onClick={() => props.onclick(gameState.id, player)}>
                <div className="player-name">{player.name}</div>
                <div className="player-word">{player.word && (props.show ? player.word : "???")}</div>
            </li>
        })

        let joinTeam : undefined | ((id: string) => void) = undefined;
        const player = props.playerId && gameState.players[props.playerId];
        if( player && !player.team ) {
            joinTeam = (teamId: string) => {
                api.joinTeam(gameState.id, player!.id, teamId);
            };
        }

        return <li key={t.id}>
            <div className={gameState.active_team == t.id ? "active team-name" : "team-name"}>{t.name} {joinTeam && <button onClick={() => joinTeam!(t.id)}>Join</button>}</div>
            <ul className="player-list">{players}</ul>
        </li>
    })

    return <>
        <div id="teams">
            <ul>
                {teamElements}
            </ul>
        </div>
    </>;
}