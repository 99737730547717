import { useState, useEffect, SyntheticEvent } from 'react';

import api, { Team, Player, GameState } from '../api';
import JoinRoom from '../components/join-room';
import NewRoom from '../components/new-room';
import PlayArea from '../components/play-area';
import PlayerList from '../components/player-list';
import HintForm from '../components/hint-form';
import Hints from '../components/hints';

interface WSEvent {
    GameState: GameState,
}

export default function Room() {

    const [gameState, setGameState] = useState<GameState>();
    const [teamId, setTeamId] = useState<string | undefined>();
    const [error, setError] = useState<string>();

    const roomId = "pikkujoulut";

    useEffect(() => {
        const url = process.env.REACT_APP_BACKEND?.replace(/^http/, "ws")
        const socket = new WebSocket(`${url}/api/rooms/${roomId}/ws`)

        socket.addEventListener("message", (event) => {
            const data = JSON.parse(event.data) as WSEvent;
            console.log(data);
            if (data.GameState) {
                setGameState(data.GameState);
            }
        })
    }, [ roomId ]);

    if( !gameState ) {
        return (
            <div>Loading...</div>
        )
    }

    if( gameState.phase == "Guess" ) {
        return <Hints state={gameState} />
    }

    if( gameState.phase == "Score" ) {
        return <>
            <div id="word-reveal">{gameState.word}</div>
            <PlayerList state={gameState} show={true} onclick={(a,b) => {}}/>
        </>
    }

    return <PlayerList state={gameState} show={false} onclick={(a,b) => {}}/>
}
