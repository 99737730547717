import api, { GameState, Player } from '../api';

interface HintsProps {
    state: GameState,
}

export default function Hints(props : HintsProps) {
    const gameState = props.state;

    const hints = Object.values(gameState.players).filter(p => !p.hide_word).map(p => {
        return <li key={p.id} className="player visible">
            <div className="player-name">{p.name}</div>
            <div className="player-word">{p.word}</div>
        </li>
    });

    return <>
        <div id="hints">
            <ul className="player-list landscape-fullscreen">
                {hints}
            </ul>
        </div>
    </>;
}